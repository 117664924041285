import Icon from "./icon";
import iconList from "./icon-list";
import styles from "./promo-label.module.scss";

type Props = {
  text: string;
  iconName?: keyof typeof iconList;
};

export default function PromoLabel(props: Props) {
  return (
    <div className={styles.freeLabel}>
      <span>{props.text}</span>
      {props.iconName && <Icon name={props.iconName} />}
    </div>
  );
}
