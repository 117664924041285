import { useTranslations } from "next-intl";

import styles from "./product-info-gift.module.scss";

type Props = {
  giftCardData?: EVA.Core.GiftCard;
  physicalGiftCardRequirement?: EVA.Core.ProductRequirementLineModel[];
};

export default function ProductInfoGift(props: Props) {
  const t = useTranslations();

  return (
    <div className={styles.giftInfo}>
      {(props.giftCardData?.Data?.FromEmailAddress ||
        props.giftCardData?.Data?.From ||
        props.physicalGiftCardRequirement?.find((req) => req.Name === "SenderName")?.Value) && (
        <p>
          {t("generic.from", {
            0: props.giftCardData?.Data?.From
              ? props.giftCardData?.Data?.FromEmailAddress
                ? `${props.giftCardData?.Data?.From} (${props.giftCardData?.Data?.FromEmailAddress})`
                : props.giftCardData?.Data?.From
              : props.giftCardData?.Data?.FromEmailAddress ||
                props.physicalGiftCardRequirement?.find((req) => req.Name === "SenderName")?.Value,
          })}
        </p>
      )}
      {(props.giftCardData?.Data?.ToEmailAddress ||
        props.giftCardData?.Data?.To ||
        props.physicalGiftCardRequirement?.find((req) => req.Name === "RecipientName")?.Value) && (
        <p>
          {t("generic.to", {
            0: props.giftCardData?.Data?.To
              ? props.giftCardData?.Data?.ToEmailAddress
                ? `${props.giftCardData?.Data?.To} (${props.giftCardData?.Data?.ToEmailAddress})`
                : props.giftCardData?.Data?.To
              : props.giftCardData?.Data?.ToEmailAddress ||
                props.physicalGiftCardRequirement?.find((req) => req.Name === "RecipientName")?.Value,
          })}
        </p>
      )}
      {props.giftCardData?.Data?.Text && (
        <>
          <br />
          <p>
            {t("generic.giftMessage", {
              0: props.giftCardData?.Data?.Text,
            })}
          </p>
        </>
      )}
      {props.giftCardData?.Details?.GiftCardNumber && (
        <>
          <br />
          <p>
            <strong>
              {t("generic.giftcardCode", {
                0: props.giftCardData?.Details?.GiftCardNumber,
              })}
            </strong>
          </p>
          <p>
            <strong>
              {t("generic.giftcardPin", {
                0: props.giftCardData?.Details?.PIN,
              })}
            </strong>
          </p>
        </>
      )}
    </div>
  );
}
