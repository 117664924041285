import { useFormatter } from "next-intl";

import { useConfiguration } from "~/contexts/configuration";
import cartUtils from "~/utils/cart-utils";
import intlUtils from "~/utils/intl-utils";

import styles from "./cart-product-price.module.scss";

type Props = {
  line: EVA.Core.OrderLineDto;
  showNetPriceWithoutPromo?: boolean;
};

export default function CartProductPrice(props: Props) {
  const formatter = useFormatter();
  const priceFormatter = intlUtils.usePriceFormatter();
  const { configuration } = useConfiguration();
  const excludedTax =
    configuration.PreferredPriceDisplayMode == 1 && configuration?.CurrencyID == props.line.CurrencyID;

  const currency = props.line.CurrencyID;

  const hasDiscount = !!props.line.OriginalTotalAmount;
  // Net prices seems to be related to the overall cart, shoud be avoided in line counting
  const price = props.showNetPriceWithoutPromo
    ? Math.abs(excludedTax ? props.line.TotalAmount : props.line.TotalAmountInTax)
    : excludedTax
    ? props.line.TotalAmount
    : props.line.TotalAmountInTax;

  return (
    <div className={styles.container}>
      {hasDiscount && !cartUtils.isProductFree(props.line) && (
        <>
          <del className={styles.originalPrice}>
            {priceFormatter.currency(
              excludedTax ? props.line.OriginalTotalAmount! : props.line.OriginalTotalAmountInTax!,
              { currency }
            )}
          </del>
          <p className={styles.discount}>
            {formatter.number(
              (excludedTax
                ? props.line.TotalAmountInTax / props.line.OriginalTotalAmountInTax!
                : props.line.TotalAmount / props.line.OriginalTotalAmount!) - 1,
              {
                style: "percent",
                maximumFractionDigits: 0,
              }
            )}
          </p>
        </>
      )}
      {!cartUtils.isProductFree(props.line) && (
        <p className={styles.displayPrice}>{priceFormatter.currency(price, { currency })}</p>
      )}
    </div>
  );
}
