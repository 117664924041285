import { useTranslations } from "next-intl";

export function useFormTranslations() {
  const t = useTranslations();

  const getFieldError = (zodError: string | void, values?: Record<string, string | number | undefined>) => {
    return zodError ? t(zodError, values) : undefined;
  };

  return {
    getFieldError,
  };
}
