import { useTranslations } from "next-intl";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import { ErrorResponse } from "~/shared/api/error-response.class";

import styles from "./errors.module.scss";

type ErrorsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  error: ErrorResponse | null | void;
};

export default function Errors({ error, ...rest }: ErrorsProps) {
  const t = useTranslations();
  return error?.messages ? (
    <div className={styles.errorContainer} {...rest} role="alert">
      {error.messages.map((m, i) => {
        return (
          <p key={i}>
            {t(m.type + "." + m.message)} {error.errorCode && <span>({error.errorCode})</span>}
          </p>
        );
      })}
    </div>
  ) : null;
}
