import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";

import cartUtils from "~/utils/cart-utils";
import intlUtils from "~/utils/intl-utils";

import Icon from "../common/icon";
import PromoLabel from "../common/promo-label";
import styles from "./discount-product-tile.module.scss";

type Props = {
  line: EVA.Core.OrderLineDto;
  showNetPriceWithoutPromo?: boolean;
};

export default function DiscountProductTile({ line, showNetPriceWithoutPromo }: Props) {
  const t = useTranslations();
  const priceFormatter = intlUtils.usePriceFormatter();
  const currency = line.CurrencyID;
  const evaEndpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);
  const evaServicePrefigureDiscounts = hooks.useCallService({
    service: Core.PrefigureDiscounts,
  });

  const { data: prefiguredDiscounts } = useQuery({
    queryKey: ["prefiguredDiscounts", line.OrderID],
    staleTime: Infinity,
    queryFn: async () => {
      return await evaServicePrefigureDiscounts({ OrderID: line.OrderID });
    },
    enabled: !!evaEndpointUrl && !!line.OrderID,
  });

  const promoLabelLocalized = (discount: EVA.Core.OrderLineDiscount) => {
    return prefiguredDiscounts?.Results && prefiguredDiscounts.Results.length > 0
      ? prefiguredDiscounts.Results.find((prefiguredDiscount) => prefiguredDiscount.DiscountID === discount.DiscountID)
          ?.MarketingDescription
      : undefined;
  };

  if (showNetPriceWithoutPromo) {
    return (
      <div className={styles.discountLines}>
        {line.Discounts?.filter(
          (discount) => discount.DiscountAmount !== undefined && discount.DiscountAmount !== 0
        ).map((discount) => (
          <div key={discount.DiscountID} className={styles.discountProductContainer}>
            <div className={styles.discountProductDescription}>
              <Icon name="discount" width={16} height={16} />
              <span>{promoLabelLocalized(discount) || discount.Description}</span>
            </div>
            <span className={styles.discountProductAmount}>
              {priceFormatter.currency(discount.DiscountAmount, { currency: line.CurrencyID })}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.discountLines}>
      {line.Discounts?.filter((discount) => discount.DiscountAmount !== undefined && discount.DiscountAmount !== 0).map(
        (discount) => (
          <div key={discount.DiscountID} className={styles.discountLine}>
            {cartUtils.isProductFree(line) ? (
              <div className={styles.nameWithLabel}>
                {/* PromotionLabel should be the localized one TODO 
          ask kiko to create a CR on NewBlack for this as suggested by Stan https://support.newblack.io/support/tickets/12847 */}
                <p className={styles.name}>{promoLabelLocalized(discount) || discount.Description}</p>
                <PromoLabel iconName="gift" text={t("generic.free")} />
              </div>
            ) : (
              <>
                <p className={styles.name}>{promoLabelLocalized(discount) || discount.Description}</p>
                <p className={styles.displayPrice}>{priceFormatter.currency(discount.DiscountAmount, { currency })}</p>
              </>
            )}
          </div>
        )
      )}
    </div>
  );
}
