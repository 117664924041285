import Constants from "./constants";
import productUtils from "./product-utils";

const cartUtils = {
  calculateCartTotals(cart?: EVA.Core.ShoppingCartResponse, withTax?: boolean) {
    if (!cart) {
      return {
        total: 0,
        subtotal: 0,
        discountLines: [],
        giftWrapping: 0,
        shippingAmount: 0,
        shippingMethod: null,
        tax: 0,
        estimated: true,
      };
    }

    // cart.ShoppingCart.IsPaid should not be considered anymore since SDK update
    if (cart.ShoppingCart.Lines.length === 0) {
      return {
        total: 0,
        subtotal: 0,
        discountLines: [],
        giftWrapping: 0,
        shippingAmount: 0,
        shippingMethod: null,
        tax: 0,
        estimated: true,
      };
    }

    const discountLines = cart.Amounts.Discounts.PerDiscount;

    const subtotal = cart.Amounts.Types.NormalProduct
      ? withTax
        ? cart.Amounts.Types.NormalProduct.Amount
        : cart.Amounts.Types.NormalProduct.InTax
      : 0;
    const total = cart.Amounts.Total.InTax;
    const taxTotal = withTax ? cart.Amounts.Total.Tax : 0;
    const giftWrapping = cart.Amounts.Types.GiftWrappingCosts
      ? withTax
        ? cart.Amounts.Types.GiftWrappingCosts.Amount
        : cart.Amounts.Types.GiftWrappingCosts.InTax
      : 0;

    const shippingMethod = {
      shippingMethod:
        cart.ShoppingCart.Lines.find((line) => line.ShippingMethod && !line.IsCancelled)?.ShippingMethod!.Name || null,
      shippingMethodCode:
        cart.ShoppingCart.Lines.find((line) => line.ShippingMethod && !line.IsCancelled)?.ShippingMethod!.Code || null,
    };
    const hasShippingMethod = cart.ShoppingCart.Lines.some((line) => line.ShippingMethodID);
    const shippingCostsLine = cart.ShoppingCart.Lines.find((line) => line.Type === 5);

    if (shippingCostsLine !== undefined) {
      // The user has already selected a shipping method in the checkout page
      const shippingAmount = withTax ? shippingCostsLine.NetTotalAmount : shippingCostsLine.NetTotalAmountInTax;

      return {
        total,
        subtotal,
        discountLines: discountLines,
        giftWrapping,
        shippingAmount,
        shippingMethod: shippingMethod.shippingMethod,
        shippingMethodCode: shippingMethod.shippingMethodCode,
        tax: taxTotal,
        estimated: false,
      };
    } else if (hasShippingMethod) {
      // The user has selected a shipping method, however the line disappears when the free shipping is reached
      const shippingAmount = 0;

      return {
        total,
        subtotal,
        discountLines: discountLines,
        giftWrapping,
        shippingAmount,
        shippingMethod: shippingMethod.shippingMethod,
        shippingMethodCode: shippingMethod.shippingMethodCode,
        tax: taxTotal,
        estimated: false,
      };
    } else {
      // The user has not selected a shipping method yet
      const methods = cart.AdditionalOrderData?.AvailableShippingMethods ?? [];
      const costs = methods.map((method) => method.Costs.Amount).filter((cost) => cost > 0);

      const virtualProducts = cartUtils.getVirtualProducts(cart);

      // virtual products only cart (no shipping cost in total)
      if (!cart.ShoppingCart.HasDelivery || virtualProducts.length === cart.ShoppingCart.TotalItems) {
        const shippingAmount = 0;

        return {
          total,
          subtotal,
          discountLines: discountLines,
          giftWrapping,
          shippingAmount,
          shippingMethod: shippingMethod.shippingMethod,
          shippingMethodCode: shippingMethod.shippingMethodCode,
          tax: taxTotal,
          estimated: false,
        };
      } else {
        // for mixed cart with no shipping selected yet, add the minimum shipping cost
        const shippingAmount = costs.length > 0 ? Math.min(...costs) : 0;

        return {
          total: total + shippingAmount,
          subtotal,
          discountLines: discountLines,
          giftWrapping,
          shippingAmount,
          shippingMethod: shippingMethod.shippingMethod,
          shippingMethodCode: shippingMethod.shippingMethodCode,
          tax: taxTotal,
          estimated: true,
        };
      }
    }
  },
  calculateFreeShippingThreshold(cart: EVA.Core.ShoppingCartResponse | undefined) {
    if (!cart) {
      return undefined;
    }

    const shippingMethods = cart.AdditionalOrderData?.AvailableShippingMethods ?? [];

    if (shippingMethods.length === 0) {
      return undefined;
    }

    const thresholds = shippingMethods.map((sm) => sm.Costs.Ranges.find((r) => r.Costs === 0)!).filter(Boolean);

    if (thresholds.length === 0) {
      return undefined;
    }

    return thresholds.sort((a, b) => a.MinAmount - b.MinAmount)[0].MinAmount;
  },
  // TODO Unfortunately there is no field that tells if the product is free, for the moment FE will check if:
  // line.CustomOrderLineType exists
  // line.CustomOrderLineType === "DISCOUNTPRODUCT"
  // the subtraction between price of the product and the discount is equal to 0
  // Related New Black ticket KIKO-191
  // Related discussion https://openmindonline.slack.com/archives/C04ND8S25DZ/p1708010414600489
  isProductFree(line: EVA.Core.OrderLineDto | undefined): boolean {
    return (line !== undefined &&
      line.CustomOrderLineType === "DISCOUNTPRODUCT" &&
      line?.TotalAmountInTax !== undefined &&
      line.Discounts?.[0]?.DiscountAmount !== undefined &&
      line.TotalAmountInTax + line.Discounts[0].DiscountAmount === 0) as boolean;
  },
  findShippingMethodWithLowestFreeShipping(cart: EVA.Core.ShoppingCartResponse | undefined) {
    if (!cart) {
      return undefined;
    }

    const shippingMethods = cart.AdditionalOrderData?.AvailableShippingMethods ?? [];

    if (shippingMethods.length === 0) {
      return undefined;
    }

    const methodsWithFreeShipping = shippingMethods.filter((sm) => sm.Costs.Ranges.some((r) => r.Costs !== undefined));

    let methodWithLowestFreeShipping = methodsWithFreeShipping
      .sort((a, b) => {
        const aMinAmount = a.Costs.Ranges.find((r) => r.Costs === 0)?.MinAmount!;
        const bMinAmount = b.Costs.Ranges.find((r) => r.Costs === 0)?.MinAmount!;

        return aMinAmount - bMinAmount;
      })
      .at(0);

    if (!methodWithLowestFreeShipping) {
      // Some shipping methods have no ranges but a direct cost of 0.
      const methodWithExplicitFreeShipping = shippingMethods.find((sm) => sm.Costs.Amount === 0);

      if (!methodWithExplicitFreeShipping) {
        return undefined;
      }

      methodWithLowestFreeShipping = methodWithExplicitFreeShipping;
    }

    return {
      method: methodWithLowestFreeShipping,
      amount: methodWithLowestFreeShipping.Costs.Ranges.find((r) => r.Costs === 0)?.MinAmount!,
    };
  },
  getVirtualProducts(cart: EVA.Core.ShoppingCartResponse | undefined) {
    return cart?.ShoppingCart.Lines.filter((line) => productUtils.isVirtualProduct(line.Product?.Properties)) || [];
  },
  hasOnlyVirtualProducts(cart: EVA.Core.ShoppingCartResponse): boolean {
    return cart && this.getVirtualProducts(cart).length === cart.ShoppingCart.Lines.length;
  },
};

export default cartUtils;
